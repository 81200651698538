import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import MainButton from "../components/main-button"

export default function FiveYearAnniversarySpecial() {
  return (
    <Layout noHeader>
      <SEO
        title="Smile Inn Dental 5 Year Anniversary Initiative"
        description="In celebration of Smile Inn's five-year anniversary, we're giving back to the community that’s made it all possible! Every week throughout this special milestone, five lucky individuals will be chosen for a complimentary dental screening and cleaning. It’s our way of saying thank you for being a part of our story."
      />
      <MainContainer>
        <h1 style={{ textAlign: "center" }}>
          Smile Inn Dental 5 Year Anniversary Initiative
        </h1>
        <p style={{ textAlign: "center" }}>
          In celebration of Smile Inn's five-year anniversary, we're giving back
          to the community that’s made it all possible! Every week throughout
          this special milestone, five lucky individuals will be chosen for a
          complimentary dental screening and cleaning. It’s our way of saying
          thank you for being a part of our story. Fill out the form below for
          your chance to experience our care firsthand – because your smile is
          worth celebrating!
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6zzZOQ-9cWodPnViyb-0MWUVXegSEgrCe7ZgAtRFM4_M1wg/viewform?pli=1"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            width: "100%",
            maxWidth: 350,
            margin: "0 auto",
          }}
        >
          <MainButton
            style={{ width: "100%", maxWidth: 350, margin: "0 auto" }}
          >
            Register Now
          </MainButton>
        </a>
      </MainContainer>
    </Layout>
  )
}

const MainContainer = styled.div`
  display: flex;
  padding: 22vh 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 700px) {
    padding: 7vh 20px;
  }
`
